import type { IOrganizationId } from "@archetype/ids";
import { OrganizationId } from "@archetype/ids";
import type { GetServerSideProps, NextPage } from "next";
import { useRouter } from "next/router";
import { useEffectOnce } from "react-use";

import { AppLoader } from "../../components/AppLoader";

interface IProps {
  orgId: IOrganizationId;
}

/**
 * A simple redirect to the production workspace
 */
const Page: NextPage<IProps> = ({ orgId }) => {
  const router = useRouter();

  useEffectOnce(() => {
    void router.replace({
      pathname: "/[orgId]/workspace/[versionType]",
      query: {
        orgId,
        versionType: "prod",
      },
    });
  });

  return <AppLoader />;
};

export const getServerSideProps: GetServerSideProps<IProps> = async ({ params }) => {
  if (params?.orgId === undefined) {
    throw Error("Invalid organization id provided");
  }

  const orgId = Array.isArray(params.orgId) ? params.orgId.join("") : params.orgId;

  return {
    props: {
      orgId: OrganizationId.parse(orgId),
    },
  };
};

export default Page;
